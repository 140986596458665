


















































































































































































































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';

import {
    IFlow, IFlowField, IFlowFieldCondition, IFlowResponse,
} from '@/interfaces/flow';

const component = Base.extend({
    data() {
        return {
            defaultOpenedDetails: [],
            currentLanguage: 'en',
            draggingRow: undefined as any,
            draggingRowIndex: undefined as any,
            flow: undefined as (any | IFlow),
            fieldDataTypes: [],
            fieldInputTypes: [
                { name: 'Text', slug: 'text' },
                { name: 'Textarea', slug: 'textarea' },
                { name: 'Date', slug: 'date' },
                { name: 'Color', slug: 'color' },
                { name: 'Number', slug: 'number' },
                { name: 'Checkbox', slug: 'checkbox' },
                { name: 'Radio', slug: 'radio' },
                { name: 'Select', slug: 'select' },
            ],
        };
    },
    mounted() {
        this.getFlow();
    },
    methods: {
        dragstart(p: any) {
            const payload = p;
            this.draggingRow = payload.row;
            this.draggingRowIndex = payload.index;
        },
        dragover(p: any) {
            const payload = p;

            payload.event.target.closest('tr').classList.add('is-selected');
            payload.event.preventDefault();
        },
        dragleave(p: any) {
            const payload = p;
            payload.event.target.closest('tr').classList.remove('is-selected');
            payload.event.preventDefault();
        },
        drop(p: any, target: Array<any>) {
            target.splice(p.index, 0, ...target.splice(this.draggingRowIndex, 1));
            target.forEach((t:any, index:number) => {
                const targ = t;
                targ.position = (index + 1);
            });
        },
        removeCondition(field: number, index: number) {
            this.$delete(this.flow.fields[field].conditions, index);
        },
        addCondition(field: number) {
            const condition = {
                id: undefined,
                field_id: 1,
                condition: 'equal',
                value: '',
                flow_field_id: this.flow.fields[field].id,
            } as IFlowFieldCondition;

            this.flow.fields[field].conditions.push(condition);
        },
        removeValue(field: number, index: number) {
            this.$delete(this.flow.fields[field].values, index);
            this.$delete(this.flow.fields[field].translations.values, index);
        },
        addValue(field: number) {
            const values = {} as any;

            this.$store.getters.languages.forEach((lang: any) => {
                values[lang.code] = '';
            });

            this.flow.fields[field].translations.values.push(values);
        },
        addField() {
            this.flow.fields.push({
                id: undefined,
                flow_id: this.flow.id,
                type: 'text',
                is_required: true,
                default: '',
                options: '',
                placeholder: '',
                allow_custom_value: false,
                help: '',
                label: '',
                translations: {
                    label: {},
                    help: {},
                    values: [],
                },
                conditions: [],
                position: this.flow.fields.length + 1,
                has_comment: false,
                has_picture: false,
            } as IFlowField);
        },
        removeField(index: number) {
            this.$delete(this.flow.fields, index);
        },
        getFlow(): void {
            this.get<IFlowResponse>(`flows/${this.$route.params.id}`).then(({ data }) => {
                this.flow = data.data;
            });
        },
        saveFlow(): void {
            this.put<IFlowResponse>(`flows/${this.$route.params.id}`, {
                ...this.flow,
            }).then(({ data }) => {
                this.flow = data.data;
                this.$emit('success', { message: `field ${data.data.name} updated` });
            });
        },

    },
});

export default component;
