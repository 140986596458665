var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'flows.list'},"icon-left":"arrow-left"}}),( ! _vm.flow)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm.flow.label)+" ")])]],2)])]),(_vm.flow)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveFlow)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"name","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Name","type":{'is-danger': errors[0]}}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.flow.name),callback:function ($$v) {_vm.$set(_vm.flow, "name", $$v)},expression:"flow.name"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"horizontal":"","label":"Fields"}},[_c('div',[_c('b-table',{staticClass:"mb-4",attrs:{"data":_vm.flow.fields,"draggable":true,"detailed":true,"opened-detailed":_vm.defaultOpenedDetails,"show-detail-icon":true,"detail-transition":"fade"},on:{"dragstart":_vm.dragstart,"drop":function($event){return _vm.drop($event,_vm.flow.fields)},"dragover":_vm.dragover,"dragleave":_vm.dragleave},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('b-field',{staticClass:"mb-4",attrs:{"horizontal":"","label":"Display conditions","type":{'is-danger': _vm.errors[0]}}},[_c('div',[(props.row.conditions.length)?_c('b-table',{staticClass:"mb-4",attrs:{"data":props.row.conditions,"draggable":true},on:{"dragstart":_vm.dragstart,"drop":function($event){return _vm.drop($event,props.row.conditions)},"dragover":_vm.dragover,"dragleave":_vm.dragleave},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v(" No conditions yet ")])]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"field":"field_id","label":"Field"},scopedSlots:_vm._u([{key:"default",fn:function(fieldprops){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'field.conditions.'+fieldprops.index+'.field_id',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"expanded":""}},[_c('b-select',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(fieldprops.row.field_id),callback:function ($$v) {_vm.$set(fieldprops.row, "field_id", $$v)},expression:"fieldprops.row.field_id"}},_vm._l((_vm.flow.fields.filter(function (f) { return f.id && f.id !== props.row.id; })),function(field,f){return _c('option',{key:f,domProps:{"value":field.id}},[_vm._v(" "+_vm._s(_vm._f("truncate")(field.translations.label[_vm.currentLanguage],20))+" ")])}),0)],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"field":"condition","label":"Condition"},scopedSlots:_vm._u([{key:"default",fn:function(fieldprops){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'field.conditions.'+fieldprops.index+'.condition',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"expanded":""}},[_c('b-select',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(fieldprops.row.condition),callback:function ($$v) {_vm.$set(fieldprops.row, "condition", $$v)},expression:"fieldprops.row.condition"}},[_c('option',{attrs:{"value":"equal"}},[_vm._v(" equal ")]),_c('option',{attrs:{"value":"not_equal"}},[_vm._v(" not equal ")]),(props.row.type === 'number')?[_c('option',{attrs:{"value":"greater_or_equal"}},[_vm._v(" greater or equal ")]),_c('option',{attrs:{"value":"greater"}},[_vm._v(" greater ")]),_c('option',{attrs:{"value":"lower_or_equal"}},[_vm._v(" lower or equal ")]),_c('option',{attrs:{"value":"lower"}},[_vm._v(" lower ")])]:_vm._e()],2)],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"field":"value","label":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(fieldprops){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'field.conditions.'+fieldprops.index+'.value',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"expanded":""}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(fieldprops.row.value),callback:function ($$v) {_vm.$set(fieldprops.row, "value", $$v)},expression:"fieldprops.row.value"}})],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(fieldprops){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-danger","size":"is-small","inverted":"","icon-left":"trash"},on:{"click":function($event){return _vm.removeCondition(props.index, fieldprops.index)}}})],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-primary","outlined":"","icon-left":"plus"},on:{"click":function($event){return _vm.addCondition(props.index)}}},[_vm._v(" Add condition ")])],1)],1)]),(props.row.type === 'number')?[_c('ValidationProvider',{staticClass:"mb-4",attrs:{"immediate":"","rules":"","name":"min","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Min","type":{'is-danger': errors[0]}}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(props.row.min),callback:function ($$v) {_vm.$set(props.row, "min", $$v)},expression:"props.row.min"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"immediate":"","rules":"","name":"max","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Max","type":{'is-danger': errors[0]}}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(props.row.max),callback:function ($$v) {_vm.$set(props.row, "max", $$v)},expression:"props.row.max"}})],1)]}}],null,true)})]:_vm._e(),(props.row.type === 'select' || props.row.type === 'checkbox' || props.row.type === 'radio')?[_c('b-field',{staticClass:"mb-4",attrs:{"horizontal":"","label":"Available values","type":{'is-danger': _vm.errors[0]}}},[_c('div',[(props.row.translations.values.length)?_c('b-table',{staticClass:"mb-4",attrs:{"data":props.row.translations.values,"draggable":true},on:{"dragstart":_vm.dragstart,"drop":function($event){return _vm.drop($event,props.row.translations.values)},"dragover":_vm.dragover,"dragleave":_vm.dragleave},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v(" No values yet ")])]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"width":"10"}},[_c('a',[_c('b-icon',{attrs:{"icon":"bars"}})],1)]),_c('b-table-column',{attrs:{"field":"value"},scopedSlots:_vm._u([{key:"default",fn:function(fieldprops){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'field.translations.values.'+fieldprops.index+'.'+_vm.currentLanguage,"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]},"expanded":""}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(fieldprops.row[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(fieldprops.row, _vm.currentLanguage, $$v)},expression:"fieldprops.row[currentLanguage]"}}),_c('b-select',{model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}},_vm._l((_vm.$store.getters.languages),function(lang,l){return _c('option',{key:l,domProps:{"value":lang.code}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0)],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(fieldprops){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"type":"is-danger","size":"is-small","inverted":"","icon-left":"trash"},on:{"click":function($event){return _vm.removeValue(props.index, fieldprops.index)}}})],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-primary","outlined":"","icon-left":"plus"},on:{"click":function($event){return _vm.addValue(props.index)}}},[_vm._v(" Add value ")])],1)],1)]),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"immediate":"","rules":"","name":"max","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Allow custom value","type":{'is-danger': errors[0]}}},[_c('b-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(props.row.allow_custom_value),callback:function ($$v) {_vm.$set(props.row, "allow_custom_value", $$v)},expression:"props.row.allow_custom_value"}})],1)]}}],null,true)})]:_vm._e(),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"immediate":"","rules":"","name":'fields.'+props.index+'.default',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Default value","type":{'is-danger': errors[0]}}},[_c('b-input',{attrs:{"expanded":"","disabled":_vm.isLoading},model:{value:(props.row.default),callback:function ($$v) {_vm.$set(props.row, "default", $$v)},expression:"props.row.default"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-4",attrs:{"immediate":"","rules":"","name":'fields.'+props.index+'.placeholder',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Placeholder","type":{'is-danger': errors[0]}}},[_c('b-input',{attrs:{"expanded":"","disabled":_vm.isLoading},model:{value:(props.row.placeholder),callback:function ($$v) {_vm.$set(props.row, "placeholder", $$v)},expression:"props.row.placeholder"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'fields.'+props.index+'.translations.name.'+_vm.currentLanguage,"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Help indications","type":{'is-danger': errors[0]}}},[_c('b-field',[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(props.row.translations.help[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(props.row.translations.help, _vm.currentLanguage, $$v)},expression:"props.row.translations.help[currentLanguage]"}}),_c('b-select',{model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}},_vm._l((_vm.$store.getters.languages),function(lang,l){return _c('option',{key:l,domProps:{"value":lang.code}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0)],1)],1)]}}],null,true)})]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('p',[_vm._v(" No fields ")])])]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"width":"10"}},[_c('a',[_c('b-icon',{attrs:{"icon":"bars"}})],1)]),_c('b-table-column',{attrs:{"field":"label","label":"Label"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'field.translations.name.'+_vm.currentLanguage,"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]}}},[_c('b-field',[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(props.row.translations.label[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(props.row.translations.label, _vm.currentLanguage, $$v)},expression:"props.row.translations.label[currentLanguage]"}}),_c('b-select',{model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}},_vm._l((_vm.$store.getters.languages),function(lang,l){return _c('option',{key:l,domProps:{"value":lang.code}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0)],1)],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"field":"type","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'fields.'+props.index+'.type',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]}}},[_c('b-select',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(props.row.type),callback:function ($$v) {_vm.$set(props.row, "type", $$v)},expression:"props.row.type"}},_vm._l((_vm.fieldInputTypes),function(type,f){return _c('option',{key:f,domProps:{"value":type.slug}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"field":"type","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":'fields.'+props.index+'.data_type',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]}}},[_c('b-select',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(props.row.type),callback:function ($$v) {_vm.$set(props.row, "type", $$v)},expression:"props.row.type"}},_vm._l((_vm.fieldDataTypes),function(type,f){return _c('option',{key:f,domProps:{"value":type.slug}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"field":"is_required","label":"Required","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":'fields.'+props.index+'.is_required',"tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":{'is-danger': errors[0]}}},[_c('b-checkbox',{attrs:{"expanded":"","disabled":_vm.isLoading},model:{value:(props.row.is_required),callback:function ($$v) {_vm.$set(props.row, "is_required", $$v)},expression:"props.row.is_required"}})],1)]}}],null,true)})]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Conditions","width":"40","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.conditions.length)?_c('b-tag',{attrs:{"type":"is-warning"}},[_c('span',[_vm._v(_vm._s(props.row.conditions.length))]),_c('b-icon',{attrs:{"size":"is-small","icon":'bolt'}})],1):[_vm._v(" - ")]]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Actions","width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-right"},[_c('b-button',{attrs:{"size":"is-small","inverted":"","type":"is-danger","icon-left":"trash"},on:{"click":function($event){return _vm.removeField(props.index)}}})],1)]}}],null,true)})],1),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"disabled":_vm.isLoading,"type":"is-primary","outlined":"","icon-left":"plus"},on:{"click":function($event){return _vm.addField()}}},[_vm._v(" Add field ")])],1)],1)]),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":_vm.isLoading},on:{"click":function($event){return _vm.saveFlow()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)])]}}],null,false,3296107720)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }